<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card>
          <v-card-title>
            {{ $t('overview.liveLastOrders.subtitle') }}
            <v-spacer />
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('overview.liveLastOrders.table.search')" single-line hide-details />
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="orders" :search="search" :loading="loading" hide-default-footer>
            <template #[`item.provider`]="{ item }">
              <span>{{ formatProvider(item.provider) }}</span>
            </template>
            <template #[`item.name`]="{ item }">
              <span v-html="displayOrderName(item)" />
            </template>
            <template #[`item.customer`]="{ item }">
              <span>{{ item.customer.firstName }} {{ item.customer.lastName }}</span>
            </template>
            <template #[`item.createdAt`]="{ item }">
              <span>{{ formatDate(item.createdAt) }}</span>
            </template>
            <template #[`item.state`]="{ item }">
              <span>{{ formatState(item.state) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';

  export default {
    name: 'LiveLastOrdersOverview',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        headers: [],
        orders: [],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        search: ''
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      this.setHeaders();
      await this.refreshData();
    },
    methods: {
      displayOrderName (order) {
        if (typeof order.name !== 'string') {
          return '';
        }

        return '<a href="' + this.$router.resolve('/search').href + '?name=' + encodeURIComponent(order.name) + '">' + order.name.toUpperCase() + '</a>';
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/last');
          if (response.data !== undefined) {
            this.orders = response.data;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('overview.error'));
        }
        this.loading = false;
      },
      setHeaders () {
        this.headers = [{
          text: this.$i18n.t('overview.liveLastOrders.header.location'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'location.city'
        }, {
          text: this.$i18n.t('overview.liveLastOrders.header.provider'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'provider'
        }, {
          text: this.$i18n.t('overview.liveLastOrders.header.name'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'name'
        }, {
          text: this.$i18n.t('overview.liveLastOrders.header.customer'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'customer'
        }, {
          text: this.$i18n.t('overview.liveLastOrders.header.amount'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'totalPrice'
        }, {
          text: this.$i18n.t('overview.liveLastOrders.header.createdAt'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'createdAt'
        }, {
          text: this.$i18n.t('overview.liveLastOrders.header.state'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'state'
        }];
      },
      formatDate (date) {
        return DateTime.fromISO(date, { setZone: true }).setZone('Europe/Paris').setLocale('fr-fr').toLocaleString(DateTime.DATETIME_SHORT);
      },
      formatProvider (provider) {
        return this.$i18n.t('order.tab.details.provider.' + provider);
      },
      formatState (state) {
        if (state === undefined) {
          return '';
        }

        return this.$i18n.t('order.tab.details.state.' + state.state);
      }
    }
  };
</script>
