<template>
  <div v-if="Object.keys(datacollectionCount).length > 0" class="text-center">
    <h3 class="pa-4">
      {{ $t('overview.liveRevenues.subtitle') }}
    </h3>
    <v-row>
      <v-col>
        <h4 v-if="totalRevenue">
          {{ $t('overview.liveRevenues.total') }} {{ totalRevenue }}{{ $t('common.price.currency.EUR.symbol') }} {{ $t('common.price.taxIncluded.symbol') }}
        </h4>
        <div class="smallChart">
          <pie-chart :chart-data="datacollectionRevenue" :options="optionsRevenue" />
        </div>
      </v-col>
      <v-col>
        <h4 v-if="totalCount">
          {{ $t('overview.liveRevenues.total') }} {{ totalCount }} {{ $tc('overview.liveRevenues.orders', totalCount) }}
        </h4>
        <div class="smallChart">
          <pie-chart :chart-data="datacollectionCount" :options="optionsCount" />
        </div>
      </v-col>
      <v-col>
        <h4 v-if="totalAverage">
          {{ $t('overview.liveRevenues.average') }} {{ totalAverage }}{{ $t('common.price.currency.EUR.symbol') }} {{ $t('common.price.taxIncluded.symbol') }}
        </h4>
        <div class="smallChart">
          <polar-chart :chart-data="datacollectionAverage" :options="optionsAverage" />
        </div>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    {{ $t('overview.noresult') }}
  </div>
</template>

<script>
  import PieChart from '@/views/components/common/chart/PieChart';
  import PolarChart from '@/views/components/common/chart/PolarChart';

  export default {
    name: 'LiveRevenuesOverview',
    components: { PieChart, PolarChart },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        datacollectionCount: {},
        totalCount: '',
        optionsCount: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom',
            align: 'center',
            symbol: ''
          },
          responsive: true
        },
        datacollectionRevenue: {},
        totalRevenue: '',
        optionsRevenue: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom',
            align: 'center',
            symbol: this.$i18n.t('common.price.currency.EUR.symbol')
          },
          responsive: true
        },
        datacollectionAverage: {},
        totalAverage: '',
        optionsAverage: {
          title: {
            display: false
          },
          scale: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom',
            align: 'center',
            symbol: this.$i18n.t('common.price.currency.EUR.symbol')
          },
          responsive: true
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/locations/revenues', { timeout: 60000 });
          if (response.data.count !== undefined && response.data.count.labels !== undefined && response.data.count.datasets !== undefined) {
            this.datacollectionCount = response.data.count;
            if (this.datacollectionCount.datasets.length === 1 && this.datacollectionCount.datasets[0].data !== undefined) {
              this.totalCount = this.datacollectionCount.datasets[0].data.reduce((total, count) => total + count, 0).toLocaleString('fr');
            }
          }
          if (response.data.revenue !== undefined && response.data.revenue.labels !== undefined && response.data.revenue.datasets !== undefined) {
            this.datacollectionRevenue = response.data.revenue;
            if (this.datacollectionRevenue.datasets.length === 1 && this.datacollectionRevenue.datasets[0].data !== undefined) {
              this.totalRevenue = this.datacollectionRevenue.datasets[0].data.reduce((total, revenue) => total + revenue, 0).toLocaleString('fr');
            }
          }
          if (response.data.average !== undefined && response.data.average.labels !== undefined && response.data.average.datasets !== undefined) {
            this.datacollectionAverage = response.data.average;
            if (this.datacollectionAverage.datasets.length === 1 && this.datacollectionAverage.datasets[0].data !== undefined && this.datacollectionAverage.datasets[0].data.length > 0) {
              this.totalAverage = (this.datacollectionAverage.datasets[0].data.reduce((totalAverage, average) => totalAverage + average, 0)
                / this.datacollectionAverage.datasets[0].data.length).toLocaleString('fr');
            }
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('overview.error'));
        }
      }
    }
  };
</script>
