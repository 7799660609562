<script>
  import { mixins, Pie } from 'vue-chartjs';
  const { reactiveProp } = mixins;

  const displayDataValues = function () {
    const ctx = this.chart.ctx;
    ctx.font = 'bold 12pt ' + this.chart.config.options.defaultFontFamily;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';

    const options = this.options;
    this.data.datasets.forEach(function (dataset) {
      for (let i = 0; i < dataset.data.length; i++) {
        const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
        const total = dataset._meta[Object.keys(dataset._meta)[0]].total;
        const midRadius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2;
        const startAngle = model.startAngle;
        const endAngle = model.endAngle;
        const midAngle = startAngle + (endAngle - startAngle) / 2;
        const x = midRadius * Math.cos(midAngle);
        const y = midRadius * Math.sin(midAngle);

        ctx.fillStyle = '#fff';
        // if (i === 3) { // Darker text color for lighter background
        //   ctx.fillStyle = '#444';
        // }
        const percent = String(Math.round(dataset.data[i] / total * 100)) + '%';
        ctx.fillText(dataset.data[i].toLocaleString('fr') + (options.legend.symbol === undefined ? '€' : options.legend.symbol), model.x + x, model.y + y);
        ctx.fillText('(' + percent + ')', model.x + x, model.y + y + 20);
      }
    });
  };

  export default {
    name: 'PieChart',
    extends: Pie,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object,
        required: true
      },
      options: {
        type: Object,
        required: false
      }
    },
    mounted () {
      // this.chartData is created in the mixin.
      // If you want to pass options please create a local options object
      const options = this.options;
      options.tooltips = {
        enabled: false
      };
      options.animation = {
        duration: 0,
        onProgress: undefined,
        onComplete: displayDataValues
      };
      options.hover = {
        animationDuration: 0
      };
      this.renderChart(this.chartData, options);
    }
  };
</script>
