<template>
  <v-container id="dashboard" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="onTabChange">
        <v-tab>{{ $t('overview.liveOrders.title') }}</v-tab>
        <v-tab>{{ $t('customerService.productionEvents.title') }} <span>{{ displayCounter(countProductionEvents) }}</span></v-tab>
        <v-tab>{{ $t('overview.livePerformances.title') }}</v-tab>
        <v-tab>{{ $t('overview.liveRevenues.title') }}</v-tab>
        <v-tab>{{ $t('overview.liveProviders.title') }}</v-tab>
        <v-tab>{{ $t('overview.liveLastOrders.title') }}</v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshCurrentTab">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-tab-item key="1">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <live-orders-overview :refresh="refreshLiveOrders" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="2" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <production-events :refresh="refreshProductionEvents" @success="success" @fail="fail" @count="counterProductionEvents" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="3">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <live-performances-overview :refresh="refreshLivePerformances" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="4">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <live-revenues-overview :refresh="refreshLiveRevenues" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="5">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <live-providers-overview :refresh="refreshLiveProviders" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="6">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <live-last-orders-overview :refresh="refreshLiveLastOrders" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import { getPageName, handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import LiveOrdersOverview from '@/views/components/business/overview/LiveOrdersOverview';
  import ProductionEvents from '@/views/components/business/customerService/ProductionEvents';
  import LivePerformancesOverview from '@/views/components/business/overview/LivePerformancesOverview';
  import LiveRevenuesOverview from '@/views/components/business/overview/LiveRevenuesOverview';
  import LiveProvidersOverview from '@/views/components/business/overview/LiveProvidersOverview';
  import LiveLastOrdersOverview from '@/views/components/business/overview/LiveLastOrdersOverview';

  export default {
    name: 'Overview',
    components: { AlertNotification, LiveProvidersOverview, LiveOrdersOverview, LivePerformancesOverview, LiveRevenuesOverview, LiveLastOrdersOverview, ProductionEvents },
    data () {
      return {
        alert: '',
        autoRefreshTimer: undefined,
        currentTab: 0,
        loading: false,
        countProductionEvents: 0,
        refreshLiveProviders: Date.now(),
        refreshProductionEvents: Date.now(),
        refreshLiveOrders: Date.now(),
        refreshLivePerformances: Date.now(),
        refreshLiveRevenues: Date.now(),
        refreshLiveLastOrders: Date.now()
      };
    },
    mounted () {
      this.refresh();
      this.autoRefreshTimer = setInterval(() => this.refresh(), 10000);
    },
    destroyed () {
      clearInterval(this.autoRefreshTimer);
    },
    methods: {
      displayCounter (counter) {
        if (counter === 0) {
          return '';
        }

        if (counter >= 10) {
          return '(10+)';
        }

        return '(' + String(counter) + ')';
      },
      refresh () {
        if (this.getDelaySinceLastUpdate() >= 30000) {
          this.refreshCurrentTab();
        } else {
          this.updateTimer();
        }
      },
      refreshCurrentTab () {
        this.onTabChange(this.currentTab);
        this.refreshProductionEvents = Date.now();
      },
      getDelaySinceLastUpdate () {
        return Date.now() - Math.max(this.refreshLiveProviders, this.refreshProductionEvents, this.refreshLiveOrders, this.refreshLivePerformances, this.refreshLiveRevenues, this.refreshLiveLastOrders);
      },
      updateTimer () {
        const delay = this.getDelaySinceLastUpdate();
        this.$store.commit('SET_PAGE_INFO_LINE1', {
          pageName: getPageName(),
          value: this.$i18n.t('order.timer.updated', { delay: String(parseInt(delay / 1000)) })
        });
      },
      onTabChange (tab) {
        switch (tab) {
          case 0:
            this.refreshLiveOrders = Date.now();
            break;
          case 1:
            this.refreshProductionEvents = Date.now();
            break;
          case 2:
            this.refreshLivePerformances = Date.now();
            break;
          case 3:
            this.refreshLiveRevenues = Date.now();
            break;
          case 4:
            this.refreshLiveProviders = Date.now();
            break;
          case 5:
            this.refreshLiveLastOrders = Date.now();
            break;
        }
        this.loading = true;
        this.currentTab = tab;
        this.updateTimer();
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      },
      counterProductionEvents (count) {
        this.countProductionEvents = count;
      }
    }
  };
</script>
