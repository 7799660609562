var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('overview.livePerformances.subtitle.now'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.tableOptions,"items":_vm.performancesNow,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.preparation",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.preparation, 15 * 60 * 1000, 20 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.secondToHHmm(item.preparation))+" ")])]}},{key:"item.waiting",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateDelay, 10 * 60 * 1000, 15 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.secondToHHmm(item.waiting))+" ")])]}},{key:"item.delivery",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateDelay, 25 * 60 * 1000, 35 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.secondToHHmm(item.delivery))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateDelay, 40 * 60 * 1000, 60 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.secondToHHmm(item.total))+" ")])]}},{key:"item.lateDelay",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateDelay, 0, 10 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.getSign(item))+_vm._s(_vm.secondToHHmm(item.lateDelay))+" ")])]}},{key:"item.lateCount",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateCount, 1, 5 ))},[_vm._v(" "+_vm._s(item.lateCount)+" ")])]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('overview.livePerformances.subtitle.today'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.tableOptions,"items":_vm.performancesToday,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.preparation",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.preparation, 15 * 60 * 1000, 20 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.secondToHHmm(item.preparation))+" ")])]}},{key:"item.waiting",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateDelay, 10 * 60 * 1000, 15 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.secondToHHmm(item.waiting))+" ")])]}},{key:"item.delivery",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateDelay, 25 * 60 * 1000, 35 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.secondToHHmm(item.delivery))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateDelay, 40 * 60 * 1000, 60 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.secondToHHmm(item.total))+" ")])]}},{key:"item.lateDelay",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateDelay, 0, 10 * 60 * 1000))},[_vm._v(" "+_vm._s(_vm.getSign(item))+_vm._s(_vm.secondToHHmm(item.lateDelay))+" ")])]}},{key:"item.lateCount",fn:function(ref){
var item = ref.item;
return [_c('span',{style:('color:' + _vm.getColor(item.lateCount, 1, 5 ))},[_vm._v(" "+_vm._s(item.lateCount)+" ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }